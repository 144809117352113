import { EventEmitter, Injectable, Input, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ApiPathsService } from "src/app/api-paths.service";
import { NewsComponent } from "../news/news.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CampoAggiuntivo, CampoUtility } from "src/app/shared/models/campo-aggiuntivo";
import { AuthenticationService } from "src/app/Login/_services/authentication.service";
import { DatePipe } from "@angular/common";
import { Task } from "../models/Task";
import { ActionButton, DynamicCrudService } from "src/app/shared/_services/dynamic-crud.service";
import { environment } from "src/environments/environment";
import { EditScadenzaComponent } from "src/app/Scadenzario/edit-scadenza/edit-scadenza.component";
import { RmaEditComponent } from "src/app/rma/rma-edit/rma-edit.component";
import { AttivitaTaskEditComponent } from "../Attivita/attivita-edit/attivita-task-edit.component";
import { InterventoComponent } from "src/app/Interventi/intervento/intervento.component";
import { ApiRestService as InterventiApiRestService } from '../../Interventi/_services/api-rest.service';
import {  AttivitaEditComponent as FormazioneAttivitaEditComponent } from "../../formazione/attivita-edit/attivita-edit.component";
import { EditEsitiRmaComponent } from "src/app/rma/edit-esiti-rma/edit-esiti-rma.component";
import { EditAttivitaRmaComponent } from "src/app/rma/edit-attivita-rma/edit-attivita-rma.component";
import { EditAttivitaClienteComponent } from "src/app/Admin/edit-attivita-cliente/edit-attivita-cliente.component";
import { RmaStoreService } from "src/app/rma/_services/rma-store.service";
import { FormazioneStoreService } from "src/app/formazione/_services/formazione-store.service";
import { AziendeStoreService } from "src/app/Admin/_service/aziende-store.service";
import { PrimaNotaStoreService } from "src/app/Prima_Nota/_services/primaNota-store.service";
import { ScadenzeStoreService } from "src/app/Scadenzario/_services/scadenze-store.service";
import { InterventiStoreService } from "src/app/Interventi/_services/interventi-store.service";
import { ValutazioniStoreService } from "../../Valutazioni/_service/valutazioni-store.service";
import { FileStoreService } from "../../shared/_services/file-store.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Permission } from "src/app/Login/_guards/Permission";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { ITemplateData } from "@syncfusion/ej2-angular-gantt";

@Injectable({ providedIn: 'root' })
export class TaskService {
  environment = environment
  constructor(
    private api: ApiPathsService,
    private Toastr: ToastrService,
    private modalService: NgbModal,
    private campoUtility: CampoUtility,
    private authSvc: AuthenticationService,
    private datepipe: DatePipe,
    private CrudService: DynamicCrudService,
    private RmaStore: RmaStoreService,
    private InterventiStore: InterventiStoreService,
    private clientiStore: AziendeStoreService,
    private formazioneStore: FormazioneStoreService,
    private primanotaStore: PrimaNotaStoreService,
    public scadenzeStore: ScadenzeStoreService,
    private ValutazioniStoreService: ValutazioniStoreService,
    private FileStore: FileStoreService,
    private route: ActivatedRoute,
    private router: Router,
    private InterventiApiRestService: InterventiApiRestService,
    private permission: Permission,
    private titleService: Title,
    private translate: TranslateService
  ) { }
  @Input() ExternalAdd: boolean = false;
  paginationValue = {
    page: 1,
    pageSize: 30,
    pagesizeserver: 1,
    newpagegenerated: 1,
    total: 0,
  }
  InitObject(Item, DynamicFields) {
    this.CrudService.InitObject(Item, DynamicFields);
  }
  addDynamicButton(dynamicField, Item, concat?: { from: string, to: string, value: any }[], copy?: { from: string, to: string, value: any }[], OnEnd?: (x) => void,) {
    this.CrudService.CreateRelatedTask(dynamicField, Item, concat, copy, OnEnd);
  }
  getparam(oggetto, param, GetArrayFirstObj: boolean = false) {
    return oggetto ? ((Array.isArray(oggetto[param]) && GetArrayFirstObj) ? (oggetto[param]?.length > 0 ? oggetto[param][0] : null) : oggetto[param]) : null;
  }
  HandleTasksCustomButtonClick(Items: any[], button: ActionButton, FromLista: boolean, OnEnd?: (x) => void) {
    if (!(Items?.length > 0) || (button?.preAlert && !confirm(button.preAlert))) return;
    if (button.restRoute?.includes(':token'))
      button.restRoute = button.restRoute.replace(':token', this.authSvc.currentUserValue?.access_token)
    if (button.preMex)
      this.Toastr.warning(button.preMex);
    var firstTask = Items[0]
    var Structure = this.campoUtility.getDynamicTaskSctructure(button.taskType ?? firstTask.type, button.taskType ? button.taskFilter : firstTask.tipo)
    var TaskEdited;
    if (!Structure) {
      this.Toastr.error("Nessun Tipo corrispondente trovato", "Errore");
      return;
    }
    if (button.type == 'edit' && button.campi) {
      var structure = Object.assign({}, Structure);
      structure['campi'] = button.campi;
      const editmodal = this.modalService.open(NewsComponent, {
        centered: true,
        backdrop: 'static',
        size: 'xl',
      });
      (<NewsComponent>editmodal.componentInstance).DynamicFields = structure;
      (<NewsComponent>editmodal.componentInstance).ExternalAdd = true;
      (<NewsComponent>editmodal.componentInstance).IsModal = true;
      (<NewsComponent>editmodal.componentInstance).Item = {};
      (<NewsComponent>editmodal.componentInstance).FromLista = FromLista;
      (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => {
        TaskEdited = data;
        Items.forEach(task => {
          this.TaskCutomButtonExecute(Structure, task, TaskEdited, button, FromLista, false, OnEnd);
        });
        //this.save(data,structure);
      });
    }
    else
      Items.forEach(task => {
        this.TaskCutomButtonExecute(Structure, task, {}, button, FromLista, false, OnEnd);
      });


  }
  //Gestione azioni dinamiche
  HandleTaskCustomButtonClick(Item, button: ActionButton, FromLista: boolean, OnEnd?: (x) => void) {
    if (Item['clientiObject'])
      Item['clienti'] = Item['clientiObject'].map(x => x.id);
    if ((button?.preAlert && !confirm(button.preAlert))) return;
    if (button.restRoute?.includes(':token'))
      button.restRoute = button.restRoute.replace(':token', this.authSvc.currentUserValue?.access_token)
    if (button.preMex)
      this.Toastr.warning(button.preMex);
    var firstTask = Item
    var Structure = this.campoUtility.getDynamicTaskSctructure(firstTask.type, firstTask.tipo)
    console.log('Item: ', Item);
    this.TaskCutomButtonExecute(Structure, Item, {}, button, FromLista, true, OnEnd);
  }
  TaskCutomButtonExecute(Structure, task: any, TaskEdited, button: ActionButton, FromLista: boolean, ShowEditModal: boolean = false, OnEnd?: (x) => void) {
    if (button.validate && !button.validate(task, this.Toastr, this.api))
      return;
    var filter = {};
    button.parameter?.forEach(param => {
      var p = param?.parameter?.split('.');
      var parameter = Object.assign({}, task)
      p?.forEach(el => {
        parameter = this.getparam(parameter, el);
      })
      filter[param.nome] = param.value ?? parameter
      console.log('task: ', parameter);
      console.log('param: ', param);
      console.log(`${filter[param.nome]} in ${param.nome}`);
    })
    if (button?.restRoute?.includes(':id'))
      button.restRoute = button.restRoute.replace(':id', task.id)
    if (button.type == 'func' && button.func) {
      button.func(task, this.api, () => {
        if (button.successMex)
          this.Toastr.success(button.successMex);
        if (button.close)
          this.close();
        if (OnEnd)
          OnEnd(task);
      });

    }
    if (button.type == 'GET' && button.restRoute)
      this.api.ClassicGet(button.restRoute).subscribe(data => {
        if (button.successMex)
          this.Toastr.success(button.successMex);
        if (button.close)
          this.close();
        if (OnEnd)
          OnEnd(data);
      

      })
    else if (button.type == 'POST' && button.restRoute)
      this.api.ClassicPost(button.restRoute, filter).subscribe(data => {
        if (button.successMex)
          this.Toastr.success(button.successMex);
        if (button.close)
          this.close();
        if (OnEnd)
          OnEnd(data);
       
      })
    else if (button.type == 'GOTO' && button.restRoute) {

      window.open(button.restRoute, '_blank');
      if (button.successMex)
        this.Toastr.success(button.successMex);
      if (button.close)
        this.close();
      if (OnEnd)
        OnEnd(button);
    }

    else if (FromLista && task?.id)
      this.api.ClassicPost(`${Structure.restRoute}/get`, { id: task.id }).subscribe(Item => {
        this.ExecuteTask(Item.data ?? Item, button, Structure, TaskEdited, FromLista, ShowEditModal, OnEnd);
      })
    else
      this.ExecuteTask(task, button, Structure, TaskEdited, FromLista, ShowEditModal, OnEnd);
  }
  ExecuteTask(Item, button: ActionButton, Structure, TaskEdited, FromLista, ShowEditModal = false, OnEnd?: (x) => void) {
    if (button?.type == 'addTask' && button?.taskType) {
      this.addDynamicButton(this.campoUtility.getDynamicTaskSctructure(button.taskType, button.taskFilter), Item, button.concat, button.copy, (x) => { 
        if (button.successMex)
          this.Toastr.success(button.successMex);
        if (button.close)
          this.close();
        if (OnEnd)
          OnEnd(Item);
      })

    }
    else if (button.type == 'addTaskRicorrente' && button?.taskType) {
      var cliente = Item['clientiObject'] ? Item['clientiObject'].map(x => x.id) : undefined;
      var tasktype = this.campoUtility.getDynamicTaskSctructure(button.taskType, button.taskFilter);
      if (!tasktype) {
        this.Toastr.error("Nessun Tipo corrispondente trovato", "Errore");
        return;
      }
      var newtask = {
        cliente: cliente ?? Item.cliente,
        clienteObject: Item.clienteObject,
        valutazione: Item.type == "V" ? Item.id : null,
        padre: (this.isTaskType(Item, ['O', 'R']) || tasktype.type == 'Y') ? Item.id : null,
        tipo: button.taskFilter,
        nome: Item.nome,
        alert_inizio_task: 0,
        alert_fine_task: 0,
      };
      this.CrudService.Concat(Item, newtask,button.concat, button.copy)
      
      if (!Item.data_fine_prevista) {
        this.Toastr.error("Indicare la data di fine per utilizzare questa funzionalità", "Errore");
        return;
      }
      if (!tasktype['campi']?.some(x => this.campoUtility.Show(x, newtask, true)))
        this.AddPeriodicTask(Item, newtask, tasktype);
      else {
        const editmodal = this.modalService.open(NewsComponent, {
          centered: true,
          backdrop: 'static',
          size: 'xl',
        });
        (<NewsComponent>editmodal.componentInstance).DynamicFields = tasktype;
        (<NewsComponent>editmodal.componentInstance).ExternalAdd = true;
        (<NewsComponent>editmodal.componentInstance).IsModal = true;
        (<NewsComponent>editmodal.componentInstance).Item = newtask;
        (<NewsComponent>editmodal.componentInstance).FromLista = FromLista;
        (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => {
          this.AddPeriodicTask(Item, data, tasktype)
        });
      }
    }
    else if (button.type == 'esegui') {
      Item.data_inizio_effettiva = this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm')
      this.save(Item, Structure, false, false, OnEnd);
    }
    else if (button.type == 'edit' && button.campi) {
      if (ShowEditModal) {
        var structure = Object.assign({}, Structure);
        structure.campi = button.campi;
        structure.sezioni = button.sezioni;
        if (button.otp) {
          this.api.ClassicPost(`${Structure.restRoute}/otp`, { id: Item.id }).subscribe(data => {
            this.Toastr.info(data.message)
          });
        }
        const editmodal = this.modalService.open(NewsComponent, {
          centered: true,
          backdrop: 'static',
          size: 'xl',
        });
        (<NewsComponent>editmodal.componentInstance).DynamicFields = structure;
        (<NewsComponent>editmodal.componentInstance).ExternalAdd = true;
        (<NewsComponent>editmodal.componentInstance).IsModal = true;
        (<NewsComponent>editmodal.componentInstance).HideDelete = true;
        (<NewsComponent>editmodal.componentInstance).FromLista = true;
        (<NewsComponent>editmodal.componentInstance).HideButtons = button.HideButtons;
        (<NewsComponent>editmodal.componentInstance).Item = Item;
        (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => {
          var structure = Object.assign({}, Structure);
          structure.campi = button.campi;
          var tsk = Object.assign({}, Item, data)
          if (button.map) {
            tsk = button.map(tsk, this.api, () => { });
          }
          this.save(tsk, structure, button.close);
          if (OnEnd)
            OnEnd(tsk);
          //this.save(data,structure);
        });
      } else {
        var structure = Object.assign({}, Structure);
        structure.campi = button.campi;
        var campiEditati = {};
        button.campi.forEach(element => {
          campiEditati[element.oggetti[0]] = TaskEdited[element.oggetti[0]];
        });
        var tsk = Object.assign(Item, campiEditati);
        if (button.map) {
          tsk = button.map(tsk, this.api, () => { });
        }
        this.save(tsk, structure, button.close);
        if (OnEnd)
          OnEnd(tsk);
      }

    } else if (button.type == 'edit' && !button.campi) {
      var structure = Object.assign({}, Structure);
      structure.campi = button.campi;
      var campiEditati = {};
      var tsk = Object.assign(Item, campiEditati);
      if (button.map) {
        tsk = button.map(tsk, this.api, () => { OnEnd(tsk); });
      }
      this.save(tsk, structure, button.close);
      if (OnEnd)
        OnEnd(tsk);
    }
  }

  save(Item, structure, close?: boolean, ExternalAdd = false, OnEnd?: (x) => void, dryRun:boolean = false) {
    if (!this.campoUtility.ValidateError(Item, structure.campi)) return;
    Item.partecipanti = Item?.partecipantiObject?.map(x => x.id)

    Item.categorie = Item?.categoria ? [Item?.categoria] : (Item.categorie ?? []);
    if (Item['clientiObject'] && structure.type == "N")
      Item['clienti'] = Item['clientiObject'].map(x => { return { id: x.id, letto: false } });
    else if (Item['clientiObject'])
      Item['clienti'] = Item['clientiObject'].map(x => { return x.id });
    Item.periodo = Item['ripetizione']?.repeatEvery;
    Item.tipo_periodo = Item['ripetizione']?.frequency;
    Item.giorno_mese_ordine = Item['ripetizione']?.ordine;
    //Item.giorno_mese_giorno = Item['ripetizione']?.daysOfWeek; !!è un array
    Item.frequenza = Item['ripetizione']?.occurrences > 1 ? Item['ripetizione']?.occurrences : undefined;

    if (ExternalAdd) {
      if (OnEnd)
        OnEnd({ Item: Item, close: close ?? false, });
      this.close();
      return;
    } else if (dryRun) {
      const Link = Item.id ? `${structure.restRoute}/update` : `${structure.restRoute}/add`;
      const string = `Link: ${Link}\n`
      console.log(string, Item)
    }
    else if (Item.id && !dryRun)
      this.api.ClassicPost(`${structure.restRoute}/update`, Item).subscribe((data) => this.saved(Item, close, OnEnd));
    else if (!dryRun)
      this.api.ClassicPost(`${structure.restRoute}/add`, Item).subscribe((data) => this.saved(data, close, OnEnd));
    if (Item.type == 'E' && Item?.tipo == 'LA' && Item.figli?.length > 0 && Item.clientiObject) {
      this.UpdateAttivitas(Item);
    }
  }
  UpdateAttivitas(Item) {
    Item?.figli?.forEach(figlio => {
      this.api.ClassicPost(`${this.campoUtility.getDynamicTaskSctructure(figlio.type, figlio?.tipo ?? null)?.restRoute}/update`, figlio).subscribe()
    })
  }
  saved(Item, close?: boolean, OnEnd?: (x) => void,) { 
    if (close)
      this.close();
    if (OnEnd)
      OnEnd({ Item: Item, close: close ?? false, });
  }
  AddPeriodicTask(Item, data: any, tasktype: any, OnEnd?: (x) => void,) {
    this.CrudService.CreatePeriodicRelatedTask(Item, data, tasktype, () => {
      this.saved(Item, false, OnEnd);
    })

  }

  isTaskType(Item, tipi: string[]) {
    return tipi?.includes(Item.type);
  }
  generateDateArray(startDate: string, endDate: string, repeatEvery: number, frequency: string, daysOfWeek: any, endCondition: string, endDateCondition: string, occurrences: number): string[] {
    var exError = this.validateRipetizione(startDate, endDate, repeatEvery, frequency, daysOfWeek, endCondition, endDateCondition, occurrences)
    if (exError) {
      this.Toastr.error(exError, 'Errore')
      return [];
    }

    const result: string[] = [];
    let currentDate = new Date(startDate);
    const finalDate = endCondition === 'date' ? new Date(endDateCondition!) : new Date(endDate);
    let count = 0;
    // Helper function to format date as yyyy-MM-dd
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    while (currentDate <= finalDate && (endCondition !== 'occurrences' || count < occurrences!)) {
      const dayOfWeek = currentDate.getDay();
      const dayMap = {
        0: 'sunday',
        1: 'monday',
        2: 'tuesday',
        3: 'wednesday',
        4: 'thursday',
        5: 'friday',
        6: 'saturday'
      };

      if (frequency !== 'weeks' || daysOfWeek[dayMap[dayOfWeek]]) {
        result.push(formatDate(currentDate));
        count++;
      }

      switch (frequency) {
        case 'days':
          currentDate.setDate(currentDate.getDate() + repeatEvery);
          break;
        case 'weeks':
          currentDate.setDate(currentDate.getDate() + repeatEvery * 7);
          break;
        case 'months':
          currentDate.setMonth(currentDate.getMonth() + repeatEvery);
          break;
        case 'years':
          currentDate.setFullYear(currentDate.getFullYear() + repeatEvery);
          break;
        default:
          throw new Error(`Unsupported frequency: ${frequency}`);
      }
    }

    return result;

  }
  generateDateArrayEveryXDays(startDate: string, intervalDays: number, endDate?: string): string[] {
    const result: string[] = [];
    let currentDate = new Date(startDate);
    var finalDate = new Date(endDate);
    finalDate.setDate(finalDate.getDate() + 1)

    if (isNaN(currentDate.getTime()) || isNaN(finalDate.getTime())) {
      console.error('Date non valide:', startDate, endDate);
      return result;
    }
    if (intervalDays <= 0) {
      console.error('intervalDays deve essere un numero positivo:', intervalDays);
      return result;
    }

    // Helper function to format date as yyyy-MM-dd
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    // Loop per generare le date
    while (currentDate <= finalDate) {
      result.push(formatDate(currentDate));
      var dummy = new Date(currentDate)
      dummy.setDate(currentDate.getDate() + intervalDays);
      currentDate = dummy;
    }
    console.log('Date da aggiungere', result);
    return result;
  }
  validateRipetizione(
    startDate: string,
    endDate: string,
    repeatEvery: number,
    frequency: string,
    daysOfWeek: any,
    endCondition: string,
    endDateCondition?: string,
    occurrences?: number
  ): string | null {
    const validFrequencies = ['days', 'weeks', 'months', 'years'];
    const validEndConditions = ['never', 'date', 'occurrences'];

    const isValidDate = (dateStr: string): boolean => !isNaN(new Date(dateStr).getTime());

    if (!isValidDate(startDate)) {
      return 'La data di inizio non è valida.';
    }

    if (endCondition === 'date' && (!endDateCondition || !isValidDate(endDateCondition))) {
      return 'La data di fine non è valida.';
    }

    if (endCondition === 'date' && new Date(endDateCondition!) <= new Date(startDate)) {
      return 'La data di fine deve essere successiva alla data di inizio.';
    }

    if (repeatEvery <= 0) {
      return 'L\'intervallo di ripetizione deve essere un numero positivo maggiore di zero.';
    }

    if (!validFrequencies.includes(frequency)) {
      return 'La frequenza non è valida.';
    }

    if (!validEndConditions.includes(endCondition)) {
      return 'La condizione di fine non è valida.';
    }

    if (endCondition === 'occurrences' && (!occurrences || occurrences <= 0)) {
      return 'Il numero di occorrenze deve essere un numero positivo maggiore di zero.';
    }

    if (frequency === 'weeks' && !Object.values(daysOfWeek).some(day => day)) {
      return 'Deve essere selezionato almeno un giorno della settimana per la frequenza settimanale.';
    }

    return null;
  }

  close() {

  }

  Open(event: Task, type: string, subtype?: string, update?:(x) => void) {
    
    let editmodal;
    var dynamicFields = this.campoUtility.getDynamicTaskSctructure(type, subtype);
      if (!dynamicFields)
        switch (type.toLocaleUpperCase()) {
          case "R":

            if (this.environment.Modules['taskboard']) {
              editmodal = this.modalService.open(AttivitaTaskEditComponent, {
                centered: true,
                backdrop: 'static',
                size: 'xl',
              });
              (<AttivitaTaskEditComponent>editmodal.componentInstance).IsModal = true;
              (<AttivitaTaskEditComponent>editmodal.componentInstance).Item = event;
              (<AttivitaTaskEditComponent>editmodal.componentInstance).updated.subscribe(data => {
                if (update)
                  update(data);
              });
            } else {
              this.RmaStore.updateSelected({ id: <number>event.id });
              editmodal = this.modalService.open(RmaEditComponent, {
                centered: true,
                backdrop: 'static',
                size: 'xl',
              });
              (<RmaEditComponent>editmodal.componentInstance).IsModal = true;
              (<RmaEditComponent>editmodal.componentInstance).added.subscribe(data => {
              });
              (<RmaEditComponent>editmodal.componentInstance).updated.subscribe(data => {
                if (update)
                  update(data);
              });
            }

            break;
          case "S":
            this.scadenzeStore.updateScadenzaSelezionata({ id: <number>event.id });
            editmodal = this.modalService.open(EditScadenzaComponent, {
              centered: true,
              backdrop: 'static',
              size: 'xl',
            });
            (<EditScadenzaComponent>editmodal.componentInstance).IsModal = true;
            (<EditScadenzaComponent>editmodal.componentInstance).added.subscribe(data => {
            });
            (<EditScadenzaComponent>editmodal.componentInstance).updated.subscribe(data => {
              if (update)
                  update(data);
            });
            break;
          case "F": //fasi
            // this.InterventiStore.updateInterventoSelezionato({id: <number>event.id});
            this.InterventiStore.updateEditable(true);
            this.InterventiStore.updateShowAddForm(false);
            this.InterventiApiRestService.getInfoIntervento(event).subscribe(data => {
              const modal = this.modalService.open(InterventoComponent, {
                centered: true,
                backdrop: 'static',
                size: 'xl',
              });
              (<InterventoComponent>modal.componentInstance).InterventoSelezionato = data.data;
              (<InterventoComponent>modal.componentInstance).Refresh.subscribe(data => {
                if (update)
                  update(data);
              });
            });
            break;
          case "A":
            let attmodal = this.modalService.open(AttivitaTaskEditComponent, {
              centered: true,
              backdrop: 'static',
              size: 'xl',
            });
            (<AttivitaTaskEditComponent>attmodal.componentInstance).IsModal = true;
            (<AttivitaTaskEditComponent>attmodal.componentInstance).Item = event ?? new Task();
            (<AttivitaTaskEditComponent>attmodal.componentInstance).updated.subscribe(data => {
              if (update)
                  update(data);
            });
            break;
          case "N":
            let Newsmodal = this.modalService.open(NewsComponent, {
              centered: true,
              backdrop: 'static',
              size: 'xl',
            });
            (<NewsComponent>Newsmodal.componentInstance).DynamicFields = {
              type: 'N',
              addButton: true,
              restRoute: '/task/news',
              Label: 'News',
              campi: [
                { nome: 'Titolo:', oggetti: ['nome'], modificabile: true, inputType: 'text', class: 'col-lg-3' },
                { nome: 'Data:', oggetti: ['data_inizio_prevista'], modificabile: true, inputType: 'datetime-local', class: 'col-lg-3' },
                { nome: 'Data Fine:', oggetti: ['data_fine_prevista'], modificabile: true, inputType: 'datetime-local', class: 'col-lg-3' },
                { nome: 'Note:', oggetti: ['note'], inputType: 'html', modificabile: true, class: 'col-lg-12' },
              ],
            };
            (<NewsComponent>Newsmodal.componentInstance).IsModal = true;
            (<NewsComponent>Newsmodal.componentInstance).Item = event ?? new Task();
            (<NewsComponent>Newsmodal.componentInstance).updated.subscribe(data => {
              if (update)
                  update(data);
            });
            break;
          case "L": //lezioni
            this.formazioneStore.updateAttivita({ id: <number>event.id });
            editmodal = this.modalService.open(FormazioneAttivitaEditComponent, {
              centered: true,
              backdrop: 'static',
              size: 'xl',
            });
            break;
          case "B": //spese
            this.primanotaStore.updateEditable(true);
            this.primanotaStore.updateAddable(false);
            this.primanotaStore.updateSpesaSelezionata({ id: <number>event.id });
            this.router.navigate([this.route.snapshot.queryParams[' primanota/spesa '] || 'primanota/spesa',]);
            break;
          case "I": //fatture
            this.primanotaStore.updateEditable(true);
            this.primanotaStore.updateAddable(false);
            this.primanotaStore.updateFatturaSelezionata({ id: <number>event.id });
            this.router.navigate([this.route.snapshot.queryParams[' primanota/fattura'] || 'primanota/fattura',]);
            break;
          case "V": //valutazione
            this.ValutazioniStoreService.updateSelected(event);
            // this.FileStore.SelectId(event.id);
            // this.FileStore.updateSelectedUrl('/task/task/file');
            this.FileStore.SelectSection('valutazione');
            this.router.navigate([this.route.snapshot.queryParams['valutazioni/editor'] || 'valutazioni/editor',]);
            break;
          case "W": //Segnalazione (sottotask rma)
            if (environment.RmaAddOn['CampiEsito']) {
              const editmodalw = this.modalService.open(EditEsitiRmaComponent, {
                centered: true,
                backdrop: 'static',
                size: 'xl',
              });
              (<EditEsitiRmaComponent>editmodalw.componentInstance).IsModal = true;
              (<EditEsitiRmaComponent>editmodalw.componentInstance).Item = event;
              (<EditEsitiRmaComponent>editmodalw.componentInstance).checkSelezionate = [];
              (<EditEsitiRmaComponent>editmodalw.componentInstance).added.subscribe(data => {
              });
              (<EditEsitiRmaComponent>editmodalw.componentInstance).updated.subscribe(data => {
                if (update)
                  update(data);
              });
            } else {
              const editmodalw = this.modalService.open(EditAttivitaRmaComponent, {
                centered: true,
                backdrop: 'static',
                size: 'xl',
              });
              (<EditAttivitaRmaComponent>editmodalw.componentInstance).IsModal = true;
              (<EditAttivitaRmaComponent>editmodalw.componentInstance).Item = event;
              (<EditAttivitaRmaComponent>editmodalw.componentInstance).updated.subscribe(data => {
                if (update)
                  update(data);
              });
            }
            break;
          case "C": //attivita cliente (CRM)
            this.clientiStore.updateSelectedAttivita({ id: <number>event.id })
            this.modalService.open(EditAttivitaClienteComponent, {
              centered: true,
              backdrop: 'static',
              size: 'xl',
            });
            break;
        }
      else {
        let Newsmodal = this.modalService.open(NewsComponent, {
          centered: true,
          backdrop: 'static',
          size: 'xl',
        });
        (<NewsComponent>Newsmodal.componentInstance).DynamicFields = dynamicFields;
        (<NewsComponent>Newsmodal.componentInstance).IsModal = true;
        (<NewsComponent>Newsmodal.componentInstance).Item = event ?? new Task();
        (<NewsComponent>Newsmodal.componentInstance).updated.subscribe(data => {
          if (update)
                  update(data);
        });
      }
    
    // var dynamicFields = this.campoUtility.getDynamicTaskSctructure(type, subtype);
    // let Newsmodal = this.modalService.open(NewsComponent, {
    //   centered: true,
    //   backdrop: 'static',
    //   size: 'xl',
    // });
    // (<NewsComponent>Newsmodal.componentInstance).DynamicFields = dynamicFields;
    // (<NewsComponent>Newsmodal.componentInstance).IsModal = true;
    // (<NewsComponent>Newsmodal.componentInstance).Item = task ?? new Task();
    // (<NewsComponent>Newsmodal.componentInstance).updated.subscribe(data => {
    //   this.Refresh.emit()
    // });
  }
  OpenFromTask(task, update?: (x) => void) {
    this.Open(task, task.type, task.tipo, update)
  }


  GetTaskFilter(taskType?: string, taskSubType?: string, viewType?: string, filter?:any, cliente?, persona?, paginationEnabled: boolean= true, Calendartype: any = null) {
    var filtro = filter ?? {};
    var typeObj = this.environment.filtrife.find(x => x.id.toLowerCase() == taskType.toLowerCase()) ?? { id: taskType };
    var filtrifeSel = typeObj ? [typeObj] : [];
    var filterObj = typeObj['SubFilter']?.find(x => x.id.toLowerCase() == taskSubType.toLowerCase()) ?? { id: taskSubType };
    var subFilter = typeObj['SubFilter'] ?? [];
    var subFilterSel = filterObj ? [filterObj] : [];
    if (paginationEnabled)
      this.setPaginationFilter(1)
    if (Calendartype) { 
      filtro = Object.assign(filtro, Calendartype.filter);
    } else {
       this.setStatoFilter(filtro, subFilterSel);
      this.setTipoFilter(filtro, filtrifeSel, taskSubType);
    }
    this.setPersonaFilter(filtro, persona);
   
    this.setDynamicTaskSettings(filtro);
    if (filtro.DynamicTaskSettings)
      filtro = Object.assign({},filtro, filtro.DynamicTaskSettings['defaultFilter'] ?? {});
    this.setAdditionalFilters(filtro, cliente, persona);
    return filtro;
  }




  setPaginationFilter(filtro, page?: number) {
    if (!page) return;
    filtro.page = (page - 1) / this.paginationValue.pagesizeserver + 1;
    filtro.size = this.paginationValue.pagesizeserver * this.paginationValue.pageSize;
  }
  private setPersonaFilter(filtro: any, persona: any) {
    filtro.persona = environment.TaskAddon['sidebarFilter'] ? persona?.id : filtro.persona;
  }

  // Imposta il filtro dello stato se selezionato
  private setStatoFilter(filtro, subFilterSel) {
    filtro.stato = subFilterSel?.length && subFilterSel[0].id !== "T" ? subFilterSel[0].id : filtro.stato;
  }

  // Imposta il filtro del tipo se selezionato
  private setTipoFilter(filtro, filtrifeSel, taskSubType) {
    if (filtrifeSel?.length > 0 && filtrifeSel[0].label && filtrifeSel[0].id !== "T" && !filtrifeSel[0]['noFilter']) {
      filtro.tipo = filtrifeSel[0].id;
    }
    if (filtrifeSel?.length > 0 && filtrifeSel[0]['tipo'] !== "T" && !filtrifeSel[0]['noFilter']) {
      filtro.tipo = filtrifeSel[0]['tipo'];
      filtro['subType'] = taskSubType;
    }
  }

  // Imposta le impostazioni dinamiche del task
  private setDynamicTaskSettings(filtro) {
    filtro.DynamicTaskSettings = this.campoUtility.getDynamicTaskSctructure(filtro.tipo, filtro['subType'] && filtro['subType'] != 0 && filtro['subType'] != '0' ? filtro['subType'] : null);
  }

  // Imposta eventuali filtri aggiuntivi
  private setAdditionalFilters(filtro, persona?, cliente?) {
    if (persona) {
      filtro.persona = persona;
    }
    if (cliente) {
      filtro.cliente = cliente;
    }
  }


  // Processa i dati del calendario
   processCalendarData(data: any, taskType: string, taskSubType?: string,) {
    const filter = this.environment?.filtrife?.find(x => x.id.toLowerCase() == taskType.toLowerCase()) ?? { id: taskType }
    const subfilter = filter['SubFilter']?.find(x => x.id.toLowerCase() == taskSubType.toLowerCase()) ?? { id: taskSubType };
    var response: { bup?: any[], tasks: any[], stampe?: any[], aperte?, collectionSize?: number} = {tasks: []};
    data.data = data.data?.map(x => {
      if (x.type === 'W') {
        x.clienteObject = x.clienteObject ?? x.padreObject?.clienteObject;
      }
      return x;
    });
    let taskList = !taskType || taskType == 'T' ? data.data : data?.data?.filter(x => x.type === (filter['tipo'] ?? filter.id) && x.abilitata !== false);

    this.updateTitle(filter);
    taskList = taskList.filter(x => (!subfilter?.hideif && this.campoUtility.CheckValue(subfilter, x)) || (subfilter?.hideif && this.campoUtility.Show({ hideif: subfilter?.hideif, modificabile: true }, x, true)));
    var tasks = this.splitTaskListIfNecessary(taskList, filter);
    response = {
      tasks: tasks,
      bup: Object.assign([], tasks),
      collectionSize: filter ? tasks.length : data.total,
      stampe: data.stampe,
      aperte: data.scadenze
    }
    if (data.total > this.paginationValue.total)
      this.paginationValue.total = data.total;
    return response;
  }

  // Aggiorna il titolo della pagina
  private updateTitle(filter) {
    var titolo
    var newTitle = filter.label && filter.id !== "T"
      ? this.translate.instant(filter.label)
      : (filter.label && filter.id !== "T"
        ? this.translate.instant(filter.label)
        : (environment.TaskAddon['listTitle'] ?? "Lista"));
    titolo = newTitle;
    this.titleService.setTitle(titolo);
  }

  // Divide la lista di task se necessario
  private splitTaskListIfNecessary(taskList: any[], filtro: any) {
    if (filtro && filtro['splitBy']) {
      const splitted = [];
      taskList.forEach(task => {
        task[filtro['splitBy']].forEach(x => {
          const out = {};
          out[filtro['splitBy']] = [x];
          filtro['copyParam']?.forEach(param => {
            if (typeof param === 'string') {
              out[param] = task[param];
            } else if (param?.fromsplitted) {
              out[param.out] = x[param?.fromsplitted];
            } else if (param?.fromsource) {
              out[param.out] = task[param?.fromsource];
            }
          });
          splitted.push(out);
        });
      });
      return splitted;
    }
    return taskList;
  }




  
}


