import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {IDropdownSettings} from "../../../LibrerieCustom/MultiselectDropdown";
import { Categorie } from 'src/app/Progetti/Models/Categorie';

@Component({
  selector: 'app-categorie-nav-select',
  templateUrl: './categorie-nav-select.component.html',
  styleUrls: ['./categorie-nav-select.component.scss']
})
export class CategorieNavSelectComponent implements OnInit ,OnChanges{
    @Input() categorie:any[];
    @Input() nameCategoria1:string;
    @Input() nameCategoria2:string;
    @Input() nameCategoria3: string;
    @Input() horizontal: boolean = false;
    @Input()
    public set cat_id(value: number | number[] | any[] | any) {
        
        if (typeof value == 'number') {
            this._cat_id = value;
            this.inputType = 'number';
        } 
        else if (Array.isArray(value) && value.length && value[0]) {
            if (typeof value[0] == 'number'){
                this._cat_id = value[0];
                this.inputType = 'numberArray';
            }
            else {
                this._cat_id = value[0].id;
                this.inputType = 'objectArray';
            }
        }
        else if (!Array.isArray(value) && value?.id){
            this._cat_id = value.id;
            this.inputType = 'object';
        }
    }
    @Output() cat_idChange = new EventEmitter<number | number[] | any[]>();
    @Input() disable: boolean = false;
    _cat_id: number;
    sottoCategoria: any;
    inputType: 'number' | 'object' | 'objectArray' | 'numberArray';
    prestazione:any;
    selectedCategoria:any;
    selectedSottoCategoria:any;
    selectedPrestazione: any;
    selCats: Categorie[] = [];
    dropSetting: IDropdownSettings= { singleSelection: true, singleOutput: true, idField: 'id', textField: 'categoria',  allowSearchFilter: true, }
    ngOnChanges(changes: SimpleChanges) {
        if(changes && changes['cat_id'] ) {
            this.selectedCategoria = this.elamiavolta(this.categorie)
            this.selectedSottoCategoria = this.elamiavolta(this.selectedCategoria?.figli);
            this.selectedPrestazione = this.elamiavolta(this.selectedSottoCategoria?.figli);
        }
      }

    ngOnInit(){
    }
    elamiavolta(cat){
        return cat?.find(x => x.id == this._cat_id) ?? cat?.find(x => x.figli?.find(y => y.id == this._cat_id)) ?? cat?.find(z => z.figli?.find(x => x.figli?.find(y => y.id == this._cat_id)))
    }

    categoriaChange(){
        this.selectedSottoCategoria = null;
        this.selectedPrestazione = null;
        if(this.selectedCategoria && this.selectedCategoria?.figli.length > 0)
            this.sottoCategoria = this.selectedCategoria.figli;
        else{
            this.EmitChange(this.selectedPrestazione, 0)
        }


    }
    sottoCategoriaChange(){
        if(this.selectedSottoCategoria && this.selectedSottoCategoria?.figli.length > 0)
            this.prestazione = this.selectedSottoCategoria.figli;
        else{
            this.EmitChange(this.selectedSottoCategoria, 1)
        }
}

    prestazioneChange() {
        if (this.selectedPrestazione) {
            this.EmitChange(this.selectedPrestazione, 2)
        }
    }

    EmitChange(cat: Categorie, level:number) {
        this._cat_id = cat.id;
        if (this.selCats.length > (level + 1))
            this.selCats.slice(level)
        if (this.selCats.length == (level + 1))
            this.selCats[level] = cat;
        else
            this.selCats.push(cat);
        var output;
        if (this.inputType == 'number')
            output = this._cat_id;
        if (this.inputType == 'object')
            output = cat;
        if (this.inputType == 'numberArray')
            output = [this._cat_id];
        if (this.inputType == 'objectArray')
            output = [cat]
        this.cat_idChange.emit(output);
        console.log('EmitChange: ', output, cat, this.selCats);
        console.log('CatType: ', this.inputType);

    }

}
